import React from "react";
import Swiper from "react-id-swiper";

const Autoplay = () => {
    const params = {
        spaceBetween: 30,
        centeredSlides: false,
        autoplay: {
            delay: 6500,
            disableOnInteraction: false,
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
    };

    return (
        <Swiper {...params}>
            <div className="slide1">
                <div className="slide1-title">
                    <p>A true Digital Partner</p>
                </div>
                <div className="slide1-desc ">
                    <h4>When you partner with Postlight, we assemble an experienced interdisciplinary team to make your vision real.</h4>
                </div>
            </div>
            <div className="slide2">
                <div className="slide2-title">
                    <h4>Services That We Deliver Makes Us Dignified As One of The Top App Development Companies</h4>
                </div>
            </div>
        </Swiper>
    );
};

export default Autoplay;

